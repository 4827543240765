import { Autocomplete, Box, TextField } from "@mui/material"
import NewModal from "../../../../../components/ui/NewModal";
import { useEffect, useState } from "react";
import useFetcher from "../../../../../hooks/useFetcher";
import Loader from "../../../../../components/ui/Loader";

type FilterProps = {
    open: boolean;
    handleClose: any;
    onSave: any;
    results?: any[]
}


const AddCaregiverModal = ({ open, handleClose, onSave, results = [] }: FilterProps) => {
    const [caregivers, setCaregivers] = useState<any[]>([]);
    const [selectedCaregiver, setSelectedCaregiver] = useState<any>(null);
    const [filteredCaregivers, setFilteredCaregivers] = useState<any[]>([])
    const fetcher = useFetcher();

    useEffect(() => {
        console.log(caregivers);
    }, [caregivers])

    useEffect(() => {
        if (caregivers == undefined)
            return;

        const existingIds: string[] = results?.map((row: any) => row.caregiver?.caregiverId ?? row.caregiver?.id ?? row.caregiverId ?? row.id) ?? [];
        const filtered = caregivers?.filter(c => !existingIds?.find(ex => ex == c.caregiverId))
        setFilteredCaregivers(filtered)
    }, [caregivers])

    const getCaregivers = () => {
        console.log('get');
        fetcher.get('Caregiver').then((res: any) => {
            const list = res?.data?.map((item: any): any => getWithSuffix(item))
            console.log('res', res);
            console.log('list', list);
            setCaregivers(list)
        })
    }

    const handelCloseModal = () => {
        setSelectedCaregiver(null)
        handleClose()
    }

    useEffect(() => {
        if (!open || !!caregivers?.length)
            return;
        getCaregivers();
    }, [open])

    const handleChangeCaregiver = (option: any, reason: any) => {
        if (reason == 'clear')
            setSelectedCaregiver(null)

        const selected = caregivers.find((p: any) => p.name == option)
        if (selected != null)
            setSelectedCaregiver(selected)
    }

    const handleSave = () => {
        onSave(selectedCaregiver)
        handelCloseModal()
    }

    return (
        <NewModal
            open={open}
            onClose={() => handelCloseModal()}
            title="Add Caregiver to Results"
            btnTitle="Add"
            height="200px"
            disabled={!selectedCaregiver}
            onSave={() => handleSave()}
            zIndex={9990}
        >
            {/* <Loader isLoading={fetcher.isLoading} /> */}
            <Autocomplete
                loading={fetcher.isLoading}
                sx={{ marginTop: '10px' }}
                onChange={(e, option, reason) => { handleChangeCaregiver(option, reason); }}
                size="small"
                getOptionLabel={(option) => option}
                defaultValue={selectedCaregiver?.name}
                renderInput={(params) => (
                    <TextField
                        sx={{ marginTop: '10px' }}
                        {...params}
                        value={selectedCaregiver?.name}
                        label="Select Caregiver"
                    />
                )}
                options={filteredCaregivers?.map((caregiver: any) => caregiver.name)}
                componentsProps={{
                    popper: {
                        sx: { zIndex: 99999 }, // Set zIndex for the dropdown
                    },
                }}
            />
        </NewModal>
    )
}

export default AddCaregiverModal

const getWithSuffix = (item: any): any => {
    const suffix = item?.source == 'Candidate' ? '[CANDIDATE]' : '';
    const updated: any = { ...item, name: `${item.name}  ${suffix}` }
    return updated;
}
