import { Avatar, Box, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, GridSize, IconButton, Link, List, ListItemButton, ListItemText, Paper, TextareaAutosize, Tooltip, Typography } from "@mui/material"
import { BoldTitle, FlexBetween, Title } from "../../assets/styles/styledComponents"
import { useEffect, useState } from "react"
import useFetcher from "../../hooks/useFetcher"
import { dateTime, getShortTimeFormat } from "../../helper/datetime"
import { Check, Close, Sync, SyncDisabled } from "@mui/icons-material"
import Loader from "../../components/ui/Loader"
import { useParams, useSearchParams } from "react-router-dom"
import useToast from "../../hooks/useToast"
import { app_blue_light_bg, app_light, app_Light_bg, app_pink_light_bg, app_xxx_light_bg } from "../../data/constants"
import useScreenSizes from "../../hooks/useScreenSizes"

type response = {
    inviteVisitId: number,
    status: string,
    once?: boolean,
}

type detailProps = {
    title: string,
    value: string,
    titleXs?: boolean | GridSize | undefined,
    valueXs?: boolean | GridSize | undefined,
    fontSize?: string,
}

export const DetailFieldElement = ({ title, value, fontSize = '0.9rem' }: detailProps) => (
    <Grid container spacing={1}>
        <Grid item>
            <Typography marginTop={'3px'} textAlign='left' fontSize={fontSize}>
                <b>{title}</b>:
            </Typography>
        </Grid>
        <Grid item>
            <Tooltip title={value}>
                <Typography marginTop={'3px'} textAlign='left' fontSize={fontSize}>
                    {value}
                </Typography>
            </Tooltip>
        </Grid>
    </Grid>
)
export const DetailField = ({ title, value, fontSize = '0.9rem' }: detailProps) => (
    <FlexBetween padding='0px 10px 0px 0px'>
        <Typography marginTop={'3px'} textAlign='left' fontSize={fontSize}>
            <b>{title}</b> :
        </Typography>
        <Typography marginTop={'3px'} textAlign='left' fontSize={fontSize}>
            {value}
        </Typography>
    </FlexBetween>
)
export const DetailFieldGrid = ({ title, value, titleXs, valueXs, fontSize = '0.9rem' }: detailProps) => (
    <Grid container sx={{ padding: '0px 0px 0px 0px' }}>
        <Grid item xs={titleXs}>
            <Typography marginTop={'3px'} textAlign='left' fontSize={fontSize}>
                <b>{title}</b> :
            </Typography>
        </Grid>
        <Grid item xs={valueXs}>
            <Typography marginTop={'3px'} textAlign='left' fontSize={fontSize}>
                {value}
            </Typography>
        </Grid>
    </Grid>
)

const InviteDetails = () => {
    const { inviteCode, agencyId } = useParams();
    const [queryParams, setQueryParams] = useSearchParams()
    const token = queryParams.get('t');
    const fetcher = useFetcher(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, token);
    const [details, setInviteDetails] = useState<any>();
    const [acceptedVisits, setAcceptedVisits] = useState<number[]>([]);
    const [declinedVisits, setDeclinedVisits] = useState<number[]>([]);
    const [acceptedOnceInvites, setAcceptedOnceInvites] = useState<number[]>([]);
    const [bundle, setBundle] = useState<any>({});
    const [notInterested, setNotInterested] = useState(false);
    const [reasons, setReasons] = useState('');
    const [coordinatorContact, setCoordinatorContact] = useState(false);
    const [comment, setComment] = useState<string>();
    const [readMode, setReadMode] = useState(false);
    const { showSuccess, showError } = useToast();
    const { isMdPlus } = useScreenSizes();

    // statuses
    const [overallStatus, setOverallStatus] = useState<string>(''); // response status, i.e. Responded / Invited
    const [isExpired, set_isExpired] = useState<Boolean>(false);
    const [isValid, set_isValid] = useState<Boolean>(true);
    const [isTaken, set_isTaken] = useState<Boolean>(false);

    const DeclineReasons = [
        { label: bundle['tooFar'], value: 'far' },
        { label: bundle['timing'], value: 'timing' },
        { label: bundle['tooManyHours'], value: 'hours' },
        { label: bundle['patientSpecific'], value: 'patient' },
        { label: bundle['gender'], value: 'gender' },
        { label: bundle['location'], value: 'location' }
    ]

    const getInviteDetails = () => {
        fetcher.get(`InviteResponse/${inviteCode}/${agencyId}?token=${token}`).then(res => {
            setInviteDetails(res.data.data)
        }).catch((err: any) => {
            showError(err.response.data.message)
        })
    }

    const getBundle = () => {
        fetcher.get(`Bundle/invite/${inviteCode}/${agencyId}?token=${token}`).then(res => {
            setBundle(res.data.bundle)
        }).catch((err: any) => {
            console.log('error response', err.response)
        })
    }

    const getPayload = () => {
        let invites: response[] = [];

        acceptedVisits.map((visit) => {
            invites.push({
                inviteVisitId: visit,
                status: 'Accepted',
                once: acceptedOnceInvites.includes(visit)
            })
        })

        declinedVisits.map((visit) => {
            invites.push({
                inviteVisitId: visit,
                status: 'Declined',
                once: acceptedOnceInvites.includes(visit)
            })
        })

        if (notInterested) {
            invites = []
            details.visits.map((visit: any) => {
                invites.push({
                    inviteVisitId: visit.inviteVisitId,
                    status: 'Declined',
                    once: acceptedOnceInvites.includes(visit.inviteVisitId)
                })
            })
        }

        return {
            Invites: invites,
            Reasons: notInterested ? reasons.split(',') : [''],
            CoordinatorContact: coordinatorContact,
            CaregiverComment: comment
        }
    }

    const SubmitResponse = () => {
        const payload = getPayload()
        if (payload.Invites.length < details.visits.length) {
            showError("Please have all shifts either accept or declined")
            return;
        }

        fetcher.post(`InviteResponse/update/${inviteCode}/${agencyId}?token=${token}`, payload).then(res => {
            showSuccess(bundle['responseReceived'])
            setOverallStatus('Responded')
        }).catch((err: any) => {
            err.response.data.message == 'Not A Valid Token' && setOverallStatus('Invalid')
        })
    }

    useEffect(() => {
        getInviteDetails();
        getBundle()
    }, [])

    const AddToAccepted = (visitId: any) => {
        if (declinedVisits.includes(visitId)) {
            const declined = declinedVisits.filter((v) => v !== visitId);
            setDeclinedVisits(declined);
        }

        if (!acceptedVisits.includes(visitId)) {
            setAcceptedVisits((prevAccepted) => [...prevAccepted, visitId]);
        } else {
            setAcceptedVisits((prevAccepted) => prevAccepted.filter((v) => v !== visitId));
        }
    };

    const AddToDeclined = (visitId: any) => {
        if (acceptedVisits.includes(visitId)) {
            const accepted = acceptedVisits.filter((v) => v !== visitId);
            setAcceptedVisits(accepted);
        }

        if (!declinedVisits.includes(visitId)) {
            setDeclinedVisits((prevDeclined) => [...prevDeclined, visitId]);
        } else {
            setDeclinedVisits((prevDeclined) => prevDeclined.filter((v) => v !== visitId));
        }
    };

    const handleSendProfile = () => {
        fetcher.get(`InviteResponse/send_link/${inviteCode}/${agencyId}?token=${token}`).then((res: any) => {
            showSuccess(`Profile link has been sent to phone number ending in ${res.data.message || '-'}`)
        })
    }

    const getOverallStatus = () => {
        set_isValid(true)
        if (!!details?.expiredOn)
            set_isExpired(true)
        if (details?.visits == null) {
            set_isValid(false)
        }
        else {
            if (details?.visits?.every((visit: any) => visit.visitStatus == 'Open') && details?.visits?.every((visit: any) => visit.inviteStatus == 'Pending')) {
                set_isValid(true)
                setOverallStatus("Invited")
            }
            else if (details?.visits?.every((visit: any) => visit.visitStatus == 'Taken'))
                set_isTaken(true)
            else if (details?.visits?.every((visit: any) => visit.visitStatus == 'Deleted') || details?.visits?.every((visit: any) => visit?.isActive != undefined && visit.isActive == false)) {
                set_isValid(false)
            }
            else if (details?.visits?.every((visit: any) => visit.inviteStatus == 'Accepted' || visit.inviteStatus == 'Declined' || visit.inviteStatus == 'Pending')) {
                setOverallStatus('Responded')
                setReadMode(true)
            }
        }
    }

    useEffect(() => {
        const os = getOverallStatus()
        setReasons(details?.declineReasons)
        setNotInterested(!!details?.declineReasons)
    }, [details])
    useEffect(() => {

    }, [overallStatus])

    useEffect(() => {
        details?.visits?.map((visit: any) => {
            if (visit.inviteStatus == "Accepted")
                AddToAccepted(visit.inviteVisitId)
            if (visit.inviteStatus == "Declined")
                AddToDeclined(visit.inviteVisitId)
        })
    }, [details])

    const addToReasons = (value: string) => {
        let reasonsList = reasons?.split(',') ?? [];
        if (reasonsList?.some(r => r == value)) {
            const removed = reasonsList?.filter(r => r !== value)
            setReasons(removed?.join(','))
        } else {
            const newList = reasonsList?.concat(value)
            setReasons(newList?.join(','))
        }
    }

    const toCamelCase = (sentence: string): string => {
        return sentence
            .toLowerCase()
            .replace(/ (\w)/g, (match, chr) => chr.toUpperCase());
    }

    const handleOnce = (id: any, value: any) => {
        if (value == true) {
            if (acceptedOnceInvites.includes(id))
                return;
            else
                setAcceptedOnceInvites([...acceptedOnceInvites, id])
        } else if (value == false) {
            if (!acceptedOnceInvites.includes(id))
                return;
            else
                setAcceptedOnceInvites(acceptedOnceInvites.filter(a => a != id))
        }
    }

    function getAgeRange1to5(age: number): string {
        const base = Math.floor(age / 5) * 5;
        const range = `${base + 1}-${base + 5}`;
        return range;
    }

    const DetailsSection =
        <Box display={'flex'} justifyContent={'center'} paddingBottom={'10px'}>
            <Box sx={{ width: '100%', maxWidth: '400px' }}>
                {
                    //!!details?.inviteCode ?
                    <div>
                        {overallStatus == 'Responded' && <Box marginBottom={'10px'} justifyContent={'center'}>
                            <Chip label={<BoldTitle>{bundle['responseReceived']} <span onClick={() => setReadMode(!readMode)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>{bundle['editResponse']}</span></BoldTitle>} sx={{ width: '100%', borderRadius: '5px', background: '#e8f5e9', color: 'black' }} size="small" color="success" />
                        </Box>}
                        {/* <Typography textAlign={'center'} fontSize={'16px'} variant='h6' fontWeight={'bold'} marginBottom={'8px'}>{bundle['visitDetails']}</Typography> */}
                        <DetailFieldElement title={bundle['inviteCode']} fontSize="13px" value={details?.inviteCode} />
                        {/* <DetailFieldElement title={bundle['coordinator']} fontSize="13px" value={details?.coordinator != undefined ? (details?.coordinator?.email) : details?.coordinatorName} /> */}
                        <DetailFieldElement title={bundle['at']} fontSize="13px" value={details?.patientAddress} />
                        <DetailFieldElement title={bundle['travelTime']} fontSize="13px" value={details?.travelTime == 0 ? ' - ' : details?.travelTime + ' ' + bundle['minutes']} />

                        {/* <Box fontSize={'14px'}>
                            <b>{bundle['patientAge']}:</b> {getAgeRange1to5(details?.patientAge)},
                            <b>{bundle['language']}:</b>
                             {details?.patientLanguage || 'N/A'} |
                            <b>{bundle['hoyerLift']}:</b> 
                            {bundle[details?.patientHoyerLift?.toLowerCase()] || 'N/A'} |
                            <b>{bundle['patientGender']}:</b> {details?.patientGender == 'Male' ? bundle['male'] : details?.patientGender == 'Female' ? bundle['female'] : '-'} |
                            <b>{bundle['bedbound']}:</b> {bundle[details?.patientBedbound?.toLowerCase()] || 'N/A'} |
                            <b>{bundle['smoking']}:</b> {bundle[details?.patientSmoking?.toLowerCase()] || 'N/A'}
                        </Box> */}

                        {/* <Grid container sx={{ width: '100%', margin: '15px 0px 0px 4px' }}> */}
                        {/* <Grid item? xs={5.9}> */}
                        <div>
                            <Chip
                                sx={{
                                    height: '20px',
                                    borderRadius: '5px',
                                    cursor: 'default',
                                    background: app_Light_bg,
                                    marginRight: '2px',
                                    '.MuiChip-label': {
                                        paddingLeft: '7px',
                                        paddingRight: '7px',
                                    }
                                }}
                                label={getAgeRange1to5(details?.patientAge)}
                            />
                            {(details?.patientGender && details?.patientGender?.toLowerCase() != "na") && <Chip
                                sx={{
                                    height: '20px',
                                    borderRadius: '5px',
                                    cursor: 'default',
                                    background: app_Light_bg,
                                    marginRight: '2px',
                                    '.MuiChip-label': {
                                        paddingLeft: '7px',
                                        paddingRight: '7px',
                                    }
                                }}
                                label={bundle[details?.patientGender.toLowerCase()]}
                            />}
                            {(details?.patientLanguage && details?.patientLanguage?.toLowerCase() != "na") &&
                                details?.patientLanguageList.map((lang: string) => {
                                    return < Chip
                                        key={lang}
                                        sx={{
                                            height: '20px',
                                            borderRadius: '5px',
                                            cursor: 'default',
                                            background: app_blue_light_bg,
                                            marginRight: '2px',
                                            '.MuiChip-label': {
                                                paddingLeft: '7px',
                                                paddingRight: '7px',
                                            }
                                        }}
                                        label={bundle[toCamelCase(lang)]}
                                    />
                                }
                                )}



                            {(details?.patientHoyerLift && details?.patientHoyerLift?.toLowerCase() == 'yes') && <Chip
                                sx={{
                                    height: '20px',
                                    borderRadius: '5px',
                                    cursor: 'default',
                                    background: app_pink_light_bg,
                                    marginRight: '2px',
                                    '.MuiChip-label': {
                                        paddingLeft: '7px',
                                        paddingRight: '7px',
                                    }
                                }}
                                label={bundle['hoyerLift_pref']}
                            />}
                            {(details?.patientBedbound && details?.patientBedbound?.toLowerCase() == 'yes') && <Chip
                                sx={{
                                    height: '20px',
                                    borderRadius: '5px',
                                    cursor: 'default',
                                    background: app_pink_light_bg,
                                    marginRight: '2px',
                                    '.MuiChip-label': {
                                        paddingLeft: '7px',
                                        paddingRight: '7px',
                                    }
                                }}
                                label={bundle['bedbound_pref']}
                            />}
                            {(details?.patientSmoking && details?.patientSmoking?.toLowerCase() == 'yes') && <Chip
                                sx={{
                                    height: '20px',
                                    borderRadius: '5px',
                                    cursor: 'default',
                                    background: app_pink_light_bg,
                                    marginRight: '2px',
                                    '.MuiChip-label': {
                                        paddingLeft: '7px',
                                        paddingRight: '7px',
                                    }
                                }}
                                label={bundle['smoking_pref']}
                            />}
                            {/* <DetailField fontSize="13px" title={bundle['patientAge']} value={getAgeRange1to5(details?.patientAge)} /> */}
                            {/* <DetailField fontSize="13px" title={bundle['language']} value={details?.patientLanguage || 'N/A'} /> */}
                            {/* <DetailField fontSize="13px" title={bundle['hoyerLift']} value={bundle[details?.patientHoyerLift?.toLowerCase()] || 'N/A'} /> */}
                            {/* </Grid> */}
                            {/* <Grid item xs={0.2}>
                            </Grid>
                            <Grid item xs={5.9}>
                                <DetailField fontSize="13px" title={bundle['patientGender']} value={details?.patientGender == 'Male' ? bundle['male'] : details?.patientGender == 'Female' ? bundle['female'] : '-'} />
                                <DetailField fontSize="13px" title={bundle['bedbound']} value={bundle[details?.patientBedbound?.toLowerCase()] || 'N/A'} />
                                <DetailField fontSize="13px" title={bundle['smoking']} value={bundle[details?.patientSmoking?.toLowerCase()] || 'N/A'} />
                            </Grid> */}
                            {/* </Grid> */}
                        </div>
                        {/* <BoldTitle margin='15px 0px 0px 0px' textAlign='center'>{bundle['selectVisits']}</BoldTitle> */}
                        <div style={{ marginTop: '0px', display: 'flex', justifyContent: 'center' }}>
                            <List sx={{ width: '100%' }}>
                                {details?.visits?.filter((v: any) => v.status != 'Revoked').map((visit: any) =>
                                    <ListItemButton
                                        disabled={notInterested || readMode}
                                        disableRipple
                                        sx={{
                                            padding: '0px 5px',
                                            marginTop: '2px',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            '&:hover': {
                                                background:
                                                    declinedVisits.includes(visit.inviteVisitId) ? '#f7d7dc' :
                                                        acceptedVisits.includes(visit.inviteVisitId) ? '#def5e0' :
                                                            '#fff',
                                            },
                                            background:
                                                declinedVisits.includes(visit.inviteVisitId) ? '#f7d7dc' :
                                                    acceptedVisits.includes(visit.inviteVisitId) ? '#def5e0' :
                                                        '#fff',
                                        }}
                                    >
                                        <Box sx={{ padding: 0, margin: 0 }}>
                                            <ListItemText
                                                primary={<div style={{ display: 'flex', }}>
                                                    <BoldTitle fontSize={'13px'}>{dateTime.getShortDisplayDate(visit.visitDate)} {' '}
                                                        {getShortTimeFormat(visit.visitStart)} {' - '}
                                                        {getShortTimeFormat(visit.visitEnd)}
                                                    </BoldTitle>
                                                </div>}
                                            // secondary={<div>
                                            //     <Chip
                                            //         sx={{
                                            //             height: '22px',
                                            //             borderRadius: '5px',
                                            //             cursor: 'default',
                                            //             backgroundColor: '#d2c1ff'
                                            //         }}
                                            //         label={visit.permanency == 'Permanent' ? 'P' : 'T'}
                                            //     />
                                            //     {visit.permanency == 'Permanent' &&
                                            //         <Tooltip title='I accept this visit but only for one time (temporary)'>
                                            //             <FormControlLabel
                                            //                 onClick={(e: any) => handleOnce(visit.inviteVisitId, e.target.checked)}
                                            //                 sx={{ marginLeft: '10px' }}
                                            //                 label={<BoldTitle fontSize='12px'>Just Once</BoldTitle>}
                                            //                 control={<Checkbox size="small" />}
                                            //             />
                                            //         </Tooltip>
                                            //     }
                                            // </div>}
                                            />
                                        </Box>
                                        <div style={{ display: 'block' }}>
                                            {/* {visit.permanency == 'Permanent' &&
                                                <Tooltip placement="left" title={acceptedOnceInvites.includes(visit.inviteVisitId) ? bundle['doTemp'] : bundle['doPerm']}>
                                                    <IconButton onClick={() => handleOnce(visit.inviteVisitId, acceptedOnceInvites.includes(visit.inviteVisitId) ? false : true)}>
                                                        {acceptedOnceInvites.includes(visit.inviteVisitId) ? <SyncDisabled fontSize="small" /> : <Sync fontSize="small" color="primary" />}
                                                    </IconButton>
                                                </Tooltip>} */}
                                            {<Tooltip title={bundle[visit.permanency == 'Permanent' ? 'invitedPerm' : 'invitedTemp']} enterTouchDelay={0}>
                                                <Chip
                                                    sx={{
                                                        height: '20px',
                                                        borderRadius: '3px',
                                                        cursor: 'default',
                                                        background: app_light,
                                                        marginRight: '2px',
                                                        '.MuiChip-label': {
                                                            paddingLeft: '7px',
                                                            paddingRight: '7px',
                                                        }
                                                    }}
                                                    label={visit.permanency?.toLowerCase() == 'temporary' ? bundle['once'] : bundle['ongoing']}
                                                />
                                            </Tooltip>}
                                            {visit.visitStatus == 'Taken' ? <Chip
                                                sx={{
                                                    height: '20px',
                                                    borderRadius: '3px',
                                                    cursor: 'default',
                                                    backgroundColor: 'transparent',
                                                    border: '1px solid red',
                                                    color: 'red',
                                                    width: '55px',
                                                    '.MuiChip-label': {
                                                        paddingLeft: '7px',
                                                        paddingRight: '7px',
                                                    }
                                                }}
                                                label={'Taken'}
                                            /> :
                                                <>
                                                    <Tooltip placement="left" title={bundle['available']} enterTouchDelay={0}>
                                                        {/* <IconButton disabled={visit.visitStatus == 'Taken'} sx={{ padding: '2px' }} onClick={() => AddToAccepted(visit.inviteVisitId)}>
                                                            <Check fontSize="small" color="success" />
                                                        </IconButton> */}
                                                        <Chip
                                                            disabled={visit.visitStatus == 'Taken'}
                                                            sx={{ padding: '0px', fontSize: "14px", border: 0 }}
                                                            onClick={() => AddToAccepted(visit.inviteVisitId)}
                                                            variant="outlined"
                                                            label={bundle["yes"]} color="success"
                                                        />
                                                    </Tooltip>
                                                    <Tooltip placement="right" title={bundle['notAvailable']} enterTouchDelay={0}>
                                                        {/* <IconButton disabled={visit.visitStatus == 'Taken'} sx={{ padding: '2px' }} onClick={() => AddToDeclined(visit.inviteVisitId)}>
                                                            <Close fontSize="small" color="error" />
                                                        </IconButton> */}

                                                        <Chip
                                                            disabled={visit.visitStatus == 'Taken'}
                                                            sx={{ padding: '0px', fontSize: "14px", border: 0 }}
                                                            onClick={() => AddToDeclined(visit.inviteVisitId)}
                                                            variant="outlined"
                                                            label={bundle["no"]} color="error"
                                                        />
                                                    </Tooltip>
                                                </>}
                                        </div>
                                    </ListItemButton>
                                )}
                            </List>
                        </div>
                        <Box textAlign={'center'}>
                            <Button
                                disabled={readMode}
                                color={!!notInterested ? "error" : "inherit"}
                                variant={"outlined"}
                                size="small"
                                fullWidth
                                onClick={() => setNotInterested(!notInterested)}
                                sx={{
                                    background: !!notInterested ? '#ffebee' : 'white',
                                    border: !!notInterested ? '0px solid error' : '0px solid white',
                                    borderRadius: '5px',
                                    marginBottom: '5px',
                                }}
                            >
                                {!!notInterested ? bundle['imInterested'] : bundle['imNotInterested']}
                            </Button>
                            {!!notInterested &&
                                <div >
                                    <BoldTitle>{bundle['askDeclineReasons']}</BoldTitle>
                                    <div style={{ marginTop: '10px', marginBottom: '5px' }}>
                                        {DeclineReasons.map((reason: any) =>
                                            <Chip
                                                label={reason.label}
                                                size="small"
                                                onClick={() => addToReasons(reason.value)}
                                                sx={{
                                                    margin: '1px',
                                                    border: reasons?.split(',').some(r => r == reason.value) ? '1px solid #7846ff' : '1px solid white',
                                                    background: '#e8e0ff',
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            }
                            <Box margin={'0px 0px'}>
                                <TextareaAutosize
                                    disabled={readMode}
                                    placeholder={bundle['leaveComment'] + '. . .'}
                                    onChange={(e) => setComment(e.target.value)}
                                    value={comment}
                                    style={{
                                        color: readMode ? 'rgba(0, 0, 0, 0.38)' : 'black',
                                        minWidth: '97%',
                                        maxWidth: '385px',
                                        minHeight: '50px',
                                        maxHeight: '150px',
                                        fontFamily: 'ConnectAideSecondary',
                                        padding: '5px',
                                        borderRadius: '5px',
                                    }}
                                />
                            </Box>
                            <Box sx={{ marginTop: '10px', paddingBottom: '7px', paddingLeft: '10px', borderRadius: '5px', backgroundColor: app_xxx_light_bg }}>
                                <DetailFieldElement title={bundle['coordinator']} fontSize="13px" value={''} />
                                <Title sx={{ textAlign: 'left' }}>{details?.coordinator?.name}</Title>
                                <Title sx={{ textAlign: 'left' }}>{details?.agencyPhone + (details?.coordinator?.extension != undefined && details?.coordinator?.extension > 0 ? ' # ' + details?.coordinator?.extension : '')}</Title>
                                <Title sx={{ textAlign: 'left' }}>{details?.coordinator?.email}</Title>
                            </Box>
                            <div style={{ margin: '0px auto', display: 'flex', justifyContent: 'space-between' }}>
                                <FormControl>
                                    <FormControlLabel
                                        sx={{
                                            fontSize: '10px',
                                            marginLeft: '-11px'
                                        }}
                                        disabled={readMode}
                                        label={<BoldTitle color={readMode ? 'rgba(0, 0, 0, 0.38)' : 'black'} textAlign='left' fontSize='0.8rem'>{bundle['requestCoordContact']}</BoldTitle>}
                                        control={<Checkbox onClick={() => setCoordinatorContact(!coordinatorContact)} checked={coordinatorContact} />}
                                    />
                                </FormControl>
                                <Button disabled={readMode} onClick={() => SubmitResponse()} variant="outlined" sx={{ width: '100px', border: '0px', borderRadius: '5px', margin: '0px' }}>{bundle['submit']}</Button>
                            </div>
                        </Box>
                    </div>
                    //: <div>heee</div>
                }
            </Box>
        </Box >

    const NotValidBanner =
        <Box margin={'20px'}>
            <BoldTitle textAlign='center' fontSize='15px'>{bundle['caseNotFound']}</BoldTitle>
        </Box>

    const ExpiredBanner =
        <Box margin={'20px'}>
            <BoldTitle textAlign='center' fontSize='15px'>{bundle['caseExpired']}</BoldTitle>
        </Box>
    const TakenBanner =
        <Box margin={'20px'} padding={'20px'}>
            <BoldTitle textAlign='center' fontSize='15px'>{bundle['caseTaken']?.replace('{InviteCode}', inviteCode)}</BoldTitle>
        </Box>

    return (
        <Box>
            {
                !details && !fetcher.isLoading ? <Button onClick={() => { getInviteDetails() }}>Retry</Button> : null
            }
            <Box sx={{
                minHeight: '100vh', overflowX: 'hidden', backgroundColor: '#7846ff',
                paddingTop: isMdPlus ? '10px' : '0px',
                width: isMdPlus ? '90%' : '100%',
                height: '100%',
            }} display={'flex'} justifyContent={'center'}>
                <Loader isLoading={fetcher.isLoading} />
                {details && <Box width={'100%'} height={'100%'} maxWidth={'800px'} sx={{ overflowX: 'hidden' }} justifyContent={'center'}>
                    <Paper
                        elevation={8}
                        sx={{
                            marginTop: isMdPlus ? '2%' : '0px',
                            marginBottom: isMdPlus ? '2px' : '5px',
                            padding: isMdPlus ? '0px' : '5px',
                            borderRadius: isMdPlus ? '5px' : '0px',
                        }}
                    >
                        <div style={{ margin: 'auto', width: '100%', maxWidth: '400px', }}>
                            <div style={{ marginLeft: '10px', marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                                <Avatar
                                    alt='logo'
                                    src={`/${details?.agencyName?.replace(' ', '')}.png`}
                                    sx={{ width: '50px', height: '50px' }}
                                />
                                <div style={{ marginLeft: '15px', alignItems: 'start', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <BoldTitle color='#7846ff' fontSize='18px'>{details?.agencyName}</BoldTitle>
                                    {/* <BoldTitle color='#7846ff' fontSize='10px'>Powered By ConnectAide&#174;</BoldTitle> */}
                                </div>

                            </div>
                        </div>
                        {/* is valid, but not taken, show details */}
                        {!!isValid && !isTaken && DetailsSection}
                        {/* has expired */}
                        {!!isExpired && ExpiredBanner}
                        {/* not valid and has not expired yet */}
                        {!isValid && !isExpired && NotValidBanner}
                        {/* is already taken, still valid and not expired */}
                        {!!isTaken && !!isValid && !isExpired && TakenBanner}
                    </Paper>
                    <Paper sx={{ height: '20px', }}>
                        <BoldTitle textAlign='center'>{bundle['requestProfile']}<Link sx={{ cursor: 'pointer' }} onClick={() => handleSendProfile()}> here</Link></BoldTitle>
                    </Paper>
                </Box>}
            </Box>
        </Box>
    )
}

export default InviteDetails