import { Box, FormControl, Grid, IconButton, InputLabel, ListItem, MenuItem, Select, SelectChangeEvent, SvgIcon, SvgIconProps, TextField, Tooltip } from "@mui/material";
import { WeekAvailabilityType, WorkWindowType } from "./types";
import { useEffect, useState } from "react";
import { Add, DeleteOutlineOutlined, RestoreFromTrash, Info, InfoOutlined, Check } from "@mui/icons-material";
import { LocalizationProvider, MobileTimePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BoldTitle } from "../../assets/styles/styledComponents";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { MuiButton } from "../../lib/MUI/mui.components";
import { app_Light_bg, app_light, app_purple } from "../../data/constants";
import { space } from "../../helper/strings";
import zIndex from "@mui/material/styles/zIndex";

const pickerHeight = { '& .MuiInputBase-root': { height: '30px', fontSize: '12px', padding: '0px' }, };
const PrefLevels = ['Might Work', 'Preferred', 'Unavailable'];
const LiveInOptions = [
    { key: 'Select', value: '' },
    { key: 'Yes', value: 'Yes' },
    { key: 'No', value: 'No' },
    { key: 'Only', value: 'Only' },
    { key: 'Rather Not', value: 'Rather not' }
];
const AvailabilityOptions = [
    { key: 'Select', value: '' },
    { key: 'Available', value: 'Available' },
    { key: 'Might Work', value: 'Might work' },
    { key: 'Not Available', value: 'Not available' }
];
const dayHeaderBgColor = app_light;
interface IconProps extends SvgIconProps {
    value: string;
    enabled: boolean;
}
const HomeIcon: React.FC<IconProps> = ({ value, enabled, ...props }) => {
    const getColor = (value: string) => {
        switch (value?.toLowerCase()) {
            case 'yes':
                return '#0b7e0b';
            case 'no':
                return 'grey';
            case 'only':
                return '#7846ff';
            case 'rather not':
                return 'orange';
            default:
                return 'grey';
        }
    };

    return (
        <SvgIcon {...props} style={{ color: getColor(value ?? ''), fontSize: '20px' }}>
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
            {
                value?.toLowerCase() === 'no' &&
                <>
                    <line x1="4" y1="4" x2="20" y2="20" stroke={dayHeaderBgColor || "white"} strokeWidth="6" />
                    <line x1="4" y1="4" x2="20" y2="20" stroke="currentColor" strokeWidth="2" />
                </>
            }
        </SvgIcon>
    );
};

const AvailabilityIcon: React.FC<IconProps> = ({ value, enabled, ...props }) => {
    const getColor = (value: string) => {
        switch (value?.toLowerCase()) {
            case 'available':
                return '#0b7e0b';
            case 'might work':
                return 'orange';
            case 'not available':
                return 'grey';
            default:
                return 'grey';
        }
    };

    return (
        <SvgIcon {...props} style={{ color: getColor(value ?? ''), fontSize: '20px' }}>
            <circle cx="12" cy="12" r="7" fill="currentColor" />
            {
                value?.toLowerCase() === 'not available' &&
                <>
                    <line x1="4" y1="4" x2="20" y2="20" stroke={dayHeaderBgColor || "white"} strokeWidth="6" />
                    <line x1="4" y1="4" x2="20" y2="20" stroke="currentColor" strokeWidth="2" />
                </>
            }
        </SvgIcon>
    );
};

export type DayAvailabilityProps = {
    day: string;
    liveIn: string;
    availabilityType: string;
    availability: WeekAvailabilityType;
    windows: WorkWindowType[];
    handleChangeField: (value: any, fieldName: string, window: WorkWindowType) => void;
    setOpenNote: (state: boolean) => void;
    handleAddWindow: (weekDay: string) => void;
    setSelectedWindow: (window: WorkWindowType) => void;
    acceptPredictedWindow: (window: WorkWindowType) => void;
    onChangeSelect: (event: SelectChangeEvent<any>, day: string, fieldName: string) => void;
    dirtyItems: number[];
    view?: string;
};

const DayAvailability: React.FC<DayAvailabilityProps> = ({
    day,
    liveIn,
    availabilityType,
    availability,
    windows,
    handleChangeField,
    setOpenNote,
    handleAddWindow,
    setSelectedWindow,
    acceptPredictedWindow,
    onChangeSelect,
    dirtyItems,
    view,
}) => {
    const [availTooltip, setAvailTooltip] = useState<string>();
    useEffect(() => {
        setAvailTooltip('Availability Type' + (!!availabilityType ? `: ${availabilityType}` : ''));
    }, [availabilityType]);
    const [liveInTooltip, setLiveInTooltip] = useState<string>();
    useEffect(() => {
        setLiveInTooltip('Live In' + (!!liveIn ? `: ${liveIn}` : ''));
    }, [liveIn])

    const MenuProps = {
        PaperProps: {
            sx: {
                "& .MuiMenuItem-root.Mui-disabled": {
                    opacity: 100, // Make disabled MenuItem fully transparent
                    pointerEvents: "none", // Ensure it doesn't interfere with other items
                    color: 'black',
                    backgroundColor: app_light
                }
            }
        }
    };

    return (
        <Grid key={day} item xs={12} sm={!view ? 6 : 12} md={!view ? 3 : 12} lg={!view ? 1.714 : 12}>
            <ListItem disablePadding sx={{ margin: '5px', display: 'inline-block' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '5px 0px',
                        padding: '5px 5px',
                        backgroundColor: dayHeaderBgColor,
                        borderRadius: '5px',
                    }}
                >
                    <div style={{ display: 'block', minWidth: '100%' }}>
                        <Grid container spacing={0.2}>
                            <Grid item xl={8.5} md={8} xs={10}>
                                <div>
                                    <BoldTitle>{day}</BoldTitle>
                                </div>
                            </Grid>
                            <Grid item xs={1} xl={1.5} md={2}>
                                <div style={{ overflow: 'hidden' }}>
                                    <Tooltip title={"Set your overall availability for the day. This is overridden by specific time windows if set below"}>
                                        {/* availTooltip */}
                                        <FormControl
                                            size="small"
                                            sx={{
                                                height: '20px', marginTop: '5px', padding: '0px', margin: '0px',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <Select
                                                onOpen={(e) => setAvailTooltip('')}
                                                onClose={() => setAvailTooltip(availTooltip)}
                                                sx={{
                                                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 },
                                                    '& .MuiSelect-icon': { transform: 'none' },
                                                    height: '100%', // Ensure the select takes up the full height of the FormControl
                                                    overflow: 'hidden',
                                                    width: '30px' // need max width so it doesn't overlap to other components
                                                }}
                                                size="small"
                                                value={availabilityType}
                                                onChange={(e: SelectChangeEvent<any>) => onChangeSelect(e, day, 'AvailabilityType')}
                                                IconComponent={(props) =>
                                                    <AvailabilityIcon {...props} value={availabilityType} fontSize="6px" />
                                                }
                                                renderValue={() => null}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem disabled>
                                                    <strong>Preference working on {day}s in general</strong>
                                                </MenuItem>
                                                {AvailabilityOptions.map((option) => (
                                                    <MenuItem key={option.key} value={option.value}>
                                                        {option.key}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Tooltip>
                                </div>
                            </Grid>
                            <Grid item xl={2} md={2} xs={1}>
                                <div style={{ overflow: 'hidden' }}>
                                    <Tooltip title={liveInTooltip}>
                                        <FormControl size="small" sx={{
                                            height: '20px', marginTop: '5px', padding: '0px', margin: '0px',
                                            overflow: 'hidden',
                                        }}>
                                            <Select
                                                onOpen={(e) => setLiveInTooltip('')}
                                                onClose={() => setLiveInTooltip(liveInTooltip)}
                                                sx={{
                                                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 },
                                                    '& .MuiSelect-icon': { transform: 'none' },
                                                    height: '100%', // Ensure the select takes up the full height of the FormControl
                                                    width: '30px' // need max width so it doesn't overlap to other components
                                                }}
                                                size="small"
                                                value={liveIn}
                                                onChange={(e: SelectChangeEvent<any>) => onChangeSelect(e, day, 'LiveIn')}
                                                IconComponent={(props) => (
                                                    <HomeIcon {...props} value={liveIn} fontSize="small" />
                                                )}
                                                renderValue={() => null}
                                                MenuProps={MenuProps}>
                                                <MenuItem disabled>
                                                    <strong>Willing to do live in on {day}s in general</strong>
                                                </MenuItem>
                                                {LiveInOptions.map((option) => (
                                                    <MenuItem key={option.key} value={option.value}>
                                                        {option.key}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Tooltip>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
                <Box>
                    {windows.filter((t: WorkWindowType) => t.weekDay == day && !t.isPredicted)
                        .map((window: WorkWindowType) => (
                            <Box
                                sx={{
                                    background: window.isDeleted === true ? '#f1f1f1' : dirtyItems.includes(window.id) ? '#ffdbdb7d' : '#f2edff',
                                    marginBottom: '10px', padding: '5px', borderRadius: '5px',
                                }}>
                                <div style={{ height: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                    {window.isPredicted && <AutoAwesomeIcon fontSize='small' sx={{ width: '15px', maxHeight: '12px' }} color='primary' />}
                                    {!window.isPredicted && <div style={{ fontSize: '13px', color: !!window.isDeleted ? '#9e9e9e' : '#666666' }}>{window.id}</div>}
                                    {!window.isPredicted ? <IconButton onClick={() => {
                                        //setSelectedWindow(window); setOpenDelete(true)
                                        handleChangeField(!window.isDeleted, 'isDeleted', window);
                                    }}>
                                        {
                                            !window.isDeleted ?
                                                <DeleteOutlineOutlined sx={{ width: '16px' }} fontSize="small" color="error" /> :
                                                <RestoreFromTrash sx={{ width: '16px' }} fontSize="small" color="warning" />
                                        }
                                    </IconButton> :
                                        <div>
                                            <Tooltip placement="right" title={"Confirm Predicted"} enterTouchDelay={0}>
                                                <IconButton disabled={false} sx={{ padding: '2px' }} onClick={() => acceptPredictedWindow(window)}
                                                >
                                                    <Check fontSize="small" color="success" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>}
                                </div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4} sm={6}>
                                            <MobileTimePicker
                                                onChange={(value: any) => handleChangeField(new Date(value), 'timeFrom', window)}
                                                value={!!window.timeFrom ? window.timeFrom : null}
                                                label={"From" + space(3)}
                                                disabled={!!window.isDeleted || !!window.isPredicted}
                                                renderInput={(params) =>
                                                    <TextField
                                                        sx={{
                                                            ...pickerHeight,
                                                            zIndex: 0,
                                                            '& .MuiOutlinedInput-input': {
                                                                padding: '4px 4px',
                                                            }
                                                        }}
                                                        {...params}
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={6}>
                                            <MobileTimePicker
                                                onChange={(value: any) => {
                                                    const dtValue = new Date(value);
                                                    handleChangeField(dtValue, 'timeTo', window)
                                                }}
                                                value={!!window.timeTo ? window.timeTo : null}
                                                label={"To" + space(2)}
                                                disabled={!!window.isDeleted || !!window.isPredicted}
                                                renderInput={(params) =>
                                                    <TextField
                                                        sx={{
                                                            ...pickerHeight,
                                                            zIndex: 0,
                                                            '& .MuiOutlinedInput-input': {
                                                                padding: '4px 4px',
                                                            }
                                                        }}
                                                        {...params}
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={12}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="outlined-select" sx={{zIndex: 0}}>Type</InputLabel>
                                                <Select
                                                    label={"Type" + space(3)}
                                                    disabled={!!window.isDeleted || !!window.isPredicted}
                                                    onChange={(e: any) => handleChangeField(e.target.value, 'prefLevel', window)} value={window.prefLevel} fullWidth size="small"
                                                    sx={{
                                                        fontSize: '12px',
                                                        height: '30px',
                                                        zIndex: 0,
                                                        '& .MuiOutlinedInput-input': {
                                                            padding: '4px 4px',
                                                        }
                                                    }}>
                                                    {PrefLevels.map((level: string) =>
                                                        <MenuItem key={level} value={level}>{level}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4} sm={6}>
                                            <MobileDatePicker
                                                onChange={(value: any) => handleChangeField(value, 'dateFrom', window)}
                                                value={window.dateFrom || null}
                                                label={'Beginning' + space(5)}
                                                disabled={!!window.isDeleted || !!window.isPredicted}
                                                renderInput={(params) =>
                                                    <TextField
                                                        sx={{
                                                            ...pickerHeight,
                                                            zIndex: 0,
                                                            '& .MuiOutlinedInput-input': {
                                                                padding: '4px 4px',
                                                            }
                                                        }}
                                                        {...params}
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                    />}
                                            />
                                        </Grid>

                                        <Grid item xs={4} sm={6}>
                                            <MobileDatePicker
                                                onChange={(value: any) => handleChangeField(value, 'dateTo', window)}
                                                value={window.dateTo || null}
                                                disabled={!!window.isDeleted || !!window.isPredicted}
                                                renderInput={(params) =>
                                                    <TextField
                                                        sx={{
                                                            ...pickerHeight,
                                                            zIndex: 0,
                                                            '& .MuiOutlinedInput-input': {
                                                                padding: '4px 4px',
                                                            }
                                                        }}
                                                        {...params}
                                                        fullWidth
                                                        label={'Through' + space(4)}
                                                        InputLabelProps={{ shrink: true }}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={12}>
                                            <Box margin={'0px'} marginBottom={'3px'} display={'flex'} justifyContent={'space-detween'}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={8}>
                                                        <BoldTitle fontSize='12px' fontWeight='200px' style={{ color: !!window.isDeleted ? '#9e9e9e' : '#666666' }}>
                                                            Days notice
                                                            {" "}
                                                            <Tooltip title="Reminder to update availability before this availability period ends">
                                                                <Info style={{ color: "gray", fontSize: '13px', marginBottom: '-2px' }} />
                                                            </Tooltip>
                                                        </BoldTitle>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            disabled={!!window.isDeleted || !!window.isPredicted}
                                                            value={window.remindXDaysBeforeEnds}
                                                            fullWidth
                                                            onChange={(e: any) => {
                                                                if (isNaN(Number(e.target.value)))
                                                                    return;
                                                                if (Number(e.target.value) < 0)
                                                                    return;
                                                                handleChangeField(e.target.value, 'remindXDaysBeforeEnds', window)
                                                            }}
                                                            variant='outlined'
                                                            sx={{
                                                                //width: '50px',
                                                                '& .MuiOutlinedInput-input': {
                                                                    fontSize: '15px',
                                                                    padding: '0px 5px',
                                                                    height: '25px',
                                                                    margin: 0
                                                                }
                                                            }}
                                                            size="small"
                                                            type="number"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </LocalizationProvider>

                                <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'space-between' }}>
                                    <Box display="flex" alignItems="center" sx={{ width: '100%', zIndex: 0, }}>
                                        <TextField
                                            label={"Note"}
                                            value={window.note}
                                            disabled={!!window.isDeleted || !!window.isPredicted}
                                            onClick={(e) => {
                                                setSelectedWindow(window);
                                                setOpenNote(true);
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                readOnly: true,
                                                sx: {
                                                    '& .MuiOutlinedInput-input': {
                                                        padding: '4px 4px',
                                                        fontSize: '14px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    },
                                                },
                                            }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Box>
                                </div>
                            </Box>
                        ))}
                </Box>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <MuiButton onClick={() => handleAddWindow(day)} startIcon={<Add fontSize="small" color="primary" sx={{ width: '12px' }} />} sx={{ fontSize: '12px' }}>
                        Add Availability {view === 'mobile' ? 'for ' + day : ''}
                    </MuiButton>
                </div>
            </ListItem></Grid>
    )
}

export default DayAvailability;