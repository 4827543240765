import { Box, Checkbox, Chip, Divider, FormControlLabel, FormGroup, FormLabel, IconButton, MenuItem, Paper, Select, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Bold, BoldTitle, FlexBetween, FlexContainer, Text } from "../../assets/styles/styledComponents";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import useFetcher from "../../hooks/useFetcher";
import ExpandableWidget from "../../components/common/ExpandableWidget";
import { SearchBox } from "@mapbox/search-js-react";
import { MAPBOX_TOKEN } from "../../data/constants";
import { Clear } from "@mui/icons-material";

type ControlPanelProps = {
    setFilters: any
    filters: any
    submit: any
    setChanged: any
}


const MapControlerPanel = ({ setFilters, filters, submit, setChanged }: ControlPanelProps) => {
    const [from, setFrom] = useState<Dayjs | null>(null);
    const [to, setTo] = useState<Dayjs | null>(null);
    const [offices, setOffices] = useState<any[]>([]);
    const [coordinators, setCoordinators] = useState<any[]>([]);
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

    const handleWidgetToggle = (index: number) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const expandIndexes = {
        'general': 0,
        'caregiver': 1,
        'patients': 2,
        'schedule': 3,
        'address': 4
    }

    const officesFetcher = useFetcher("filter/offices");
    const coordinatorsFetcher = useFetcher("filter/coordinators");

    useEffect(() => {
        setOffices(officesFetcher.data || []);
        setCoordinators(coordinatorsFetcher.data || []);
    }, [
        officesFetcher.data,
        coordinatorsFetcher.data,
    ]);

    const handleChange = (e: any) => {
        setChanged(true)
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        })
    }

    const handleTimeChange = (e: any, field: any, fieldString: any) => {
        field(e)
        setChanged(true)
        setFilters({
            ...filters,
            timing: filters?.timing?.map((d: any) => ({
                ...d,
                [fieldString]: e,
            }))
        })
    }

    useEffect(() => {
        if (expandedIndex !== 3)
            return;

        setFilters({
            ...filters,
            timing: filters?.timing?.map((d: any) => ({
                ...d,
                from: from?.format('hh:mm a'),
                to: to?.format('hh:mm a')
            }))
        })
    }, [from, to, filters.timing])

    const handleSwitch = (e: any) => {
        setChanged(true)
        setFilters({
            ...filters,
            [e.target.name]: !filters[e.target.name]
        })
    }

    const handleDays = (e: any) => {
        let action: any;
        if (e.target.checked == true)
            action = [...filters.timing, { day: e.target.id, from: from, to: to }]
        if (e.target.checked == false)
            action = filters.timing.filter((d: any) => d.day != e.target.id)

        setChanged(true)
        setFilters({
            ...filters,
            timing: action
        })
    }

    const statusOptions = [
        { value: 'Active', label: 'Active' },
        { value: 'Inactive', label: 'Inactive' },
        { value: 'Discharged', label: 'Discharged' },
        { value: 'On Leave', label: 'On Leave' },
    ]

    const disciplineOptions = [
        { value: 'RN' },
        { value: 'PCA' },
        { value: 'HHA' },
        { value: 'PA' },
        { value: 'All' }
    ]

    const genderOptions = [
        { value: 'Any' },
        { value: 'Male' },
        { value: 'Female' },
    ]

    const customScrollbarStyles = {
        '&::-webkit-scrollbar': {
            width: '5px',
            height: '7px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#e8e0ff',
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#7846ff',
        },
    };

    const handleAddress = (e: any) => {
        if (e.features[0].properties.feature_type === 'address' || e.features[0].properties.feature_type === 'poi') {
            setFilters({
                ...filters,
                address: {
                    addressLine1: e.features[0].properties.context.address?.name,
                    city: e.features[0].properties?.context?.locality?.name || e.features[0].properties.context.place?.name,
                    state: e.features[0].properties.context.region?.name || '',
                    country: e.features[0].properties.context.country?.name,
                    zipCode: e.features[0].properties.context.postcode?.name,
                    latitude: e.features[0].geometry.coordinates[1],
                    longitude: e.features[0].geometry.coordinates[0],
                    full: e.features[0].properties.full_address
                }
            })
        }
        if (e.features[0].properties.feature_type === 'postcode') {
            setFilters({
                ...filters,
                address: {
                    addressLine1: '',
                    city: e.features[0].properties.context.place.name,
                    state: e.features[0].properties.context.region.name,
                    country: e.features[0].properties.context.country.name,
                    zipCode: e.features[0].properties.name,
                    latitude: e.features[0].geometry.coordinates[1],
                    longitude: e.features[0].geometry.coordinates[0],
                    full: e.features[0].properties.place_formatted
                }
            })
        }
    }

    const clearAddress = () => {
        setFilters({
            ...filters,
            address: {
                addressLine1: '',
                city: '',
                state: '',
                country: '',
                zipCode: '',
                latitude: '',
                longitude: '',
                full: ''
            }
        })
    }

    return (
        <Box zIndex={10000}>
            <Paper elevation={0} sx={{ maxHeight: '90vh', backgroundColor: 'transparent', position: 'absolute', left: 8, top: '40px', zIndex: 1000 }}>
                <FlexContainer>
                    <Box sx={{ maxHeight: '90vh', overflowY: 'scroll', backgroundColor: 'transparent', height: '100%', padding: '0px', width: '300px', ...customScrollbarStyles }} >
                        <ExpandableWidget title="General" index={expandIndexes['general']} expandedIndex={expandedIndex} onToggle={handleWidgetToggle}>
                            <Bold>Office</Bold>
                            <Select
                                renderValue={(selected) => (
                                    selected.map((select: any) => {
                                        const option = offices.find((o: any) => o.id == select);
                                        return (
                                            <Chip size="small" color="primary" label={option?.officeName} sx={{ margin: '3px', height: '20px' }} />
                                        )
                                    }))}
                                multiple
                                name="offices"
                                sx={{ height: '35px', overflow: 'visible' }}
                                fullWidth
                                value={filters.offices}
                                onChange={(e) => handleChange(e)}
                            >
                                {offices.map((option: any) => (
                                    <MenuItem value={option?.id} title={option?.officeName}>{option?.officeName}</MenuItem>
                                ))}
                            </Select>

                            <Bold>Coordinator</Bold>
                            <Select
                                renderValue={(selected) => (
                                    selected.map((select: any) => {
                                        const option = coordinators.find((o: any) => o.id == select);
                                        return (
                                            <Chip size="small" color="primary" label={option?.name} sx={{ margin: '3px', height: '20px' }} />
                                        )
                                    }))}
                                multiple
                                name="coordinators"
                                sx={{ height: '35px' }}
                                fullWidth
                                value={filters.coordinators}
                                onChange={(e) => handleChange(e)}
                            >
                                {coordinators.map((option: any) => (
                                    <MenuItem value={option?.id} title={option?.name}>{option?.name}</MenuItem>
                                ))}
                            </Select>

                            <Bold>Gender</Bold>
                            <Select
                                name="gender"
                                sx={{ height: '35px' }}
                                fullWidth
                                value={filters.gender}
                                onChange={(e) => handleChange(e)}
                            >
                                {genderOptions.map((option: any) => (
                                    <MenuItem value={option?.value} title={option?.value}>{option?.value}</MenuItem>
                                ))}
                            </Select>
                        </ExpandableWidget>


                        <ExpandableWidget title="Caregiver" index={expandIndexes['caregiver']} expandedIndex={expandedIndex} onToggle={handleWidgetToggle}>
                            {/* Caregiver */}
                            <FlexBetween>
                                <BoldTitle>Show Caregivers</BoldTitle>
                                <Switch
                                    name="showCaregivers"
                                    value={filters.showCaregivers}
                                    checked={filters.showCaregivers}
                                    onClick={(e) => handleSwitch(e)}
                                />
                            </FlexBetween>

                            <Box sx={{ display: filters.showCaregivers ? 'block' : 'none' }}>
                                <Bold>Status</Bold>
                                <Select
                                    renderValue={(selected) => (
                                        selected.map((select: any) => (
                                            <Chip size="small" color="primary" label={select} sx={{ margin: '3px', height: '20px' }} />
                                        )))}
                                    multiple
                                    name="CStatus"
                                    sx={{ height: '35px' }}
                                    fullWidth
                                    value={filters.CStatus}
                                    onChange={(e) => handleChange(e)}
                                >
                                    {statusOptions.map((option) => (
                                        <MenuItem
                                            value={option?.value}
                                            title={option?.label}
                                        >
                                            {option?.label}
                                        </MenuItem>
                                    ))}
                                </Select>

                                <Bold>Discipline</Bold>
                                <Select
                                    multiple
                                    name="discipline"
                                    sx={{ height: '35px' }}
                                    fullWidth
                                    value={filters.discipline}
                                    onChange={(e) => handleChange(e)}
                                >
                                    {disciplineOptions.map((option) => (
                                        <MenuItem value={option?.value} title={option?.value}>{option?.value}</MenuItem>
                                    ))}
                                </Select>

                                <Bold>Has Schedule</Bold>
                                <Select name="hasSched" sx={{ height: '35px' }} fullWidth value={filters.hasSched} onChange={(e) => handleChange(e)}>
                                    <MenuItem value={'yes'}>Yes</MenuItem>
                                    <MenuItem value={'no'}>No</MenuItem>
                                    <MenuItem value={'all'}>All</MenuItem>
                                </Select>
                                <Bold>Hired Within</Bold>
                                <Select name="hiredAfter" sx={{ height: '35px' }} fullWidth value={filters.hiredAfter} onChange={(e) => handleChange(e)}>
                                    <MenuItem value={'all'}>Whenever</MenuItem>
                                    <MenuItem value={'30'}>30 Days</MenuItem>
                                    <MenuItem value={'60'}>60 Days</MenuItem>
                                    <MenuItem value={'90'}>90 Days</MenuItem>
                                </Select>
                                <Bold>Last Worked Within</Bold>
                                <Select name="workedAfter" sx={{ height: '35px' }} fullWidth value={filters.workedAfter} onChange={(e) => handleChange(e)}>
                                    <MenuItem value={'all'}>Whenever</MenuItem>
                                    <MenuItem value={'30'}>30 Days</MenuItem>
                                    <MenuItem value={'60'}>60 Days</MenuItem>
                                    <MenuItem value={'90'}>90 Days</MenuItem>
                                </Select>
                            </Box>
                        </ExpandableWidget>

                        <ExpandableWidget title="Patients" index={expandIndexes['patients']} expandedIndex={expandedIndex} onToggle={handleWidgetToggle}>
                            {/* Patient */}
                            <FlexBetween>
                                <BoldTitle>Show Patients</BoldTitle>
                                <Switch
                                    name="showPatients"
                                    value={filters.showPatients}
                                    checked={filters.showPatients}
                                    onClick={(e) => handleSwitch(e)}
                                />
                            </FlexBetween>

                            <Box sx={{ display: filters.showPatients ? 'block' : 'none' }}>
                                <Bold>Status</Bold>
                                <Select
                                    renderValue={(selected) => (
                                        selected.map((select: any) => (
                                            <Chip size="small" color="primary" label={select} sx={{ margin: '3px', height: '20px' }} />
                                        )))}
                                    multiple
                                    name="PStatus"
                                    sx={{ height: '35px' }}
                                    fullWidth
                                    value={filters.PStatus}
                                    onChange={(e) => handleChange(e)}
                                >
                                    {statusOptions.map((option) => (
                                        <MenuItem
                                            value={option?.value}
                                            title={option?.label}
                                        >
                                            {option?.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Bold>Has Open Visit</Bold>
                                <Select name="needsCare" sx={{ height: '35px' }} fullWidth value={filters.needsCare} onChange={(e) => handleChange(e)}>
                                    <MenuItem value={'yes'}>Yes</MenuItem>
                                    <MenuItem value={'no'}>No</MenuItem>
                                    <MenuItem value={'all'}>All</MenuItem>
                                </Select>
                            </Box>
                        </ExpandableWidget>
                        {/* <ExpandableWidget title="Schedule" index={expandIndexes['schedule']} expandedIndex={expandedIndex} onToggle={handleWidgetToggle}>
                            <FlexBetween>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Bold>From</Bold>
                                    <TimePicker
                                        onChange={(e) => handleTimeChange(e, setFrom, 'from')}
                                        value={from}
                                        renderInput={(params) =>
                                            <TextField
                                                sx={{ margin: '5px' }}
                                                {...params}
                                            />}
                                    />
                                    <Bold>To</Bold>
                                    <TimePicker

                                        onChange={(e) => handleTimeChange(e, setTo, 'to')}
                                        value={to}
                                        renderInput={(params) =>
                                            <TextField
                                                sx={{ margin: '5px' }}
                                                {...params}
                                            />}
                                    />
                                </LocalizationProvider>
                            </FlexBetween>
                            <FlexBetween>
                                <Bold>Days</Bold>
                            </FlexBetween>
                            <FlexBetween>
                                <FormGroup defaultValue={filters.timing} row onChange={(e) => handleDays(e)}>
                                    <FormControlLabel
                                        checked={filters.timing.some((t: any) => t.day === '1')}
                                        control={<Checkbox id="1" size="small" sx={{ marginRight: -1 }} />}
                                        label='S'
                                        sx={{ marginRight: '3px' }}
                                    />
                                    <FormControlLabel
                                        checked={filters.timing.some((t: any) => t.day === '2')}
                                        control={<Checkbox id="2" size="small" sx={{ marginRight: -1 }} />}
                                        label='M'
                                        sx={{ marginRight: '3px' }}
                                    />
                                    <FormControlLabel
                                        checked={filters.timing.some((t: any) => t.day === '3')}
                                        control={<Checkbox id="3" size="small" sx={{ marginRight: -1 }} />}
                                        label='T'
                                        sx={{ marginRight: '3px' }}
                                    />
                                    <FormControlLabel
                                        checked={filters.timing.some((t: any) => t.day === '4')}
                                        control={<Checkbox id="4" size="small" sx={{ marginRight: -1 }} />}
                                        label='W'
                                        sx={{ marginRight: '3px' }}
                                    />
                                    <FormControlLabel
                                        checked={filters.timing.some((t: any) => t.day === '5')}
                                        control={<Checkbox id="5" size="small" sx={{ marginRight: -1 }} />}
                                        label='T'
                                        sx={{ marginRight: '3px' }}
                                    />
                                    <FormControlLabel
                                        checked={filters.timing.some((t: any) => t.day === '6')}
                                        control={<Checkbox id="6" size="small" sx={{ marginRight: -1 }} />}
                                        label='F'
                                        sx={{ marginRight: '3px' }}
                                    />
                                    <FormControlLabel
                                        checked={filters.timing.some((t: any) => t.day === '7')}
                                        control={<Checkbox id="7" size="small" sx={{ marginRight: -1 }} />}
                                        label='S'
                                        sx={{ marginRight: '3px' }}
                                    />
                                </FormGroup>
                            </FlexBetween>
                        </ExpandableWidget> */}
                        <ExpandableWidget title="Address" index={expandIndexes['address']} expandedIndex={expandedIndex} onToggle={handleWidgetToggle}>
                            <Box>
                                <div>
                                    <div style={{ display: filters.address?.full ? 'none' : 'block' }}>
                                        <SearchBox options={{ country: 'us' }} value='' theme={{ variables: { border: '1px solid lightGray', boxShadow: 'none' } }} accessToken={MAPBOX_TOKEN} onRetrieve={(e) => handleAddress(e)} />
                                    </div>
                                    {<TextField
                                        value={filters.address?.full}
                                        fullWidth
                                        variant="standard"
                                        sx={{ display: filters.address?.full ? 'block' : 'none', border: 'none' }}
                                        aria-readonly
                                        InputProps={{
                                            disableUnderline: true,
                                            endAdornment:
                                                <IconButton onClick={() => clearAddress()}>
                                                    <Clear fontSize='small' />
                                                </IconButton>
                                        }}
                                    />}
                                </div>
                            </Box>
                        </ExpandableWidget>
                    </Box>
                </FlexContainer>
            </Paper>
        </Box>
    );
}

export default MapControlerPanel;